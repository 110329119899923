<section class="tw-flex tw-flex-wrap tw-gap-2">
  <div class="tags-list" *ngIf="tags">
    <span class="tags-list__item p-text-tag" *ngFor="let tag of tags.slice(0, max)">
      {{ tag.name }}
    </span>
    <span class="p-text-tag tw-italic !tw-tracking-normal" *ngIf="max && tags.length > max">
      +{{ tags.length - max }} more
    </span>
  </div>
  <span
    *ngIf="partnershipBacked"
    [ngClass]="PartnershipBacked[partnershipBacked]"
    class="p-text-tag tw-inline-block tw-rounded tw-bg-opacity-80 tw-px-2 tw-py-1 tw-uppercase !tw-text-white"
    >{{ 'campaign.' + PartnershipBacked[partnershipBacked] | translate }}
  </span>
  <span
    *ngFor="let item of campaignImpacts"
    [ngClass]="Impact[item.impact]"
    class="p-text-tag tw-inline-block tw-rounded tw-bg-opacity-80 tw-px-2 tw-py-1 tw-uppercase"
    >{{ 'campaign.' + Impact[item.impact] | translate }}</span
  >
</section>
